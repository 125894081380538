@import 'vars';

.OrderItem {
	display: grid;
	grid-template-columns: auto 1fr auto auto;
	gap: 16px;
	margin-bottom: 24px;
	align-items: center;
}
.OrderItem figure {
	margin: 0;
}
.OrderItem figure img {
	width: 70px;
	height: 70px;
	border-radius: 20px;
	object-fit: cover;
}
.OrderItem > img {
	cursor: pointer;
}
.OrderItem p:nth-child(2) {
	color: var(--very-light-pink);
}
.OrderItem p:nth-child(3) {
	font-size: var(--md);
	font-weight: bold;
}
@media (max-width: 640px) {
	.OrderItem > img {
		cursor: none;
	}
}