@import 'vars';

.error-container {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
}
.error-container__items {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.error-container__items > h1::before,
.error-container__items > h1,
.error-container__items > h1::after {
    font-size: 140px;
    margin-bottom: 20px;
}
.error-container__items > h1::before,
.error-container__items > h1::after{
    content: "4";
    color: var(--hospital-green);
}
.error-container__items > h2 {
    font-size: var(--lg);
    margin-bottom: 12px;
}
.error-container__items > p {
    max-width: 400px;
    font-size: var(--sm);
    margin-bottom: 20px;
}
.error-container__items > button {
    cursor: pointer;
    width: 100%;
    max-width: 100px;
    height: 50px;
    font-size: var(--md);
    font-weight: bold;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: var(--hospital-green);
}