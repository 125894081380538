@import 'vars';
.main-container {
	padding-top: 50px;
}
.ProductList {
	display: grid;
	grid-template-columns: repeat(auto-fill, 240px);
	gap: 26px;
	place-content: center;
	margin: 3em 0 0 0;
}

@media (max-width: 640px) {
	.ProductList {
		grid-template-columns: repeat(auto-fill, 140px);
	}
}
