@import 'vars';

.MyAccount {
	width: 100%;
	height: 100vh;
	display: grid;
	place-items: center;
}
.MyAccount-container {
	display: grid;
	grid-template-rows: auto 1fr auto;
	width: 300px;
}
.logo {
	width: 150px;
	margin-bottom: 48px;
	justify-self: center;
	display: none;
}
.title {
	font-size: var(--lg);
	margin-bottom: 36px;
	text-align: start;
}
.form {
	display: flex;
	flex-direction: column;
}
.form div {
	display: flex;
	flex-direction: column;
}
.label {
	font-size: var(--sm);
	font-weight: bold;
	margin-bottom: 4px;
}
.value {
	color: var(--very-light-pink);
	font-size: var(--md);
	margin: 8px 0 32px 0;
}
.secondary-button {
	background-color: var(--white);
	border-radius: 8px;
	border: 1px solid var(--hospital-green);
	color: var(--hospital-green);
	width: 100%;
	cursor: pointer;
	font-size: var(--md);
	font-weight: bold;
	height: 50px;
}
.login-button {
	margin-top: 14px;
	margin-bottom: 30px;
}
@media (max-width: 640px) {
	.form-container {
		height: 100%;
	}
	.form {
		height: 100%;
		justify-content: space-between;
	}
}
