@import 'vars';

.SendEmail {
	width: 100%;
	height: 100vh;
	display: grid;
	place-items: center;
}
.form-container {
	display: grid;
	grid-template-rows: auto 1fr auto;
	width: 300px;
	justify-items: center;
}
.logo {
	width: 150px;
	margin-bottom: 48px;
	justify-self: center;
	display: none;
}
.title {
	font-size: var(--lg);
	margin-bottom: 12px;
	text-align: center;
}
.subtitle {
	color: var(--very-light-pink);
	font-size: var(--md);
	font-weight: 300;
	margin-top: 0;
	margin-bottom: 32px;
	text-align: center;
}
.email-image {
	width: 132px;
	height: 132px;
	border-radius: 50%;
	background-color: var(--text-input-field);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;
}
.email-image img {
	width: 80px;
}
.resend {
	font-size: var(--sm);
}
.resend span {
	color: var(--very-light-pink);
}
.resend a {
	color: var(--hospital-green);
	text-decoration: none;
}
.primary-button {
	background-color: var(--hospital-green);
	border-radius: 8px;
	border: none;
	color: var(--white);
	width: 100%;
	cursor: pointer;
	font-size: var(--md);
	font-weight: bold;
	height: 50px;
}
.login-button {
	margin-top: 14px;
	margin-bottom: 30px;
}
@media (max-width: 640px) {
	.logo {
		display: block;
	}
}
