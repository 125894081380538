@import 'vars';
@import 'animations';
.Menu {
	width: 100px;
	height: auto;
	border: 1px solid var(--very-light-pink);
	border-radius: 6px;
	padding: 20px 20px 0 20px;
	position: absolute;
	top: 60px;
	right: 50px;
	background-color: white;
	-webkit-animation: fade-in-top 0.9s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-top 0.9s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.Menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.Menu ul li {
	text-align: end;
}
.Menu ul li:nth-child(1),
.Menu ul li:nth-child(2) {
	font-weight: bold;
}
.Menu ul li:last-child {
	padding-top: 20px;
	border-top: 1px solid var(--very-light-pink);
}
.Menu ul li:last-child a {
	color: var(--hospital-green);
	font-size: var(--sm);
}
.Menu ul li a {
	color: var(--back);
	text-decoration: none;
	margin-bottom: 20px;
	display: inline-block;
}
