@import 'vars';

.ProductItem {
	width: 240px;
}
.ProductItem img {
	width: 240px;
	height: 240px;
	border-radius: 20px;
	object-fit: cover;
	cursor: pointer;
}
.product-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
}
.product-info figure {
	margin: 0;
}
.product-info figure img {
	cursor: pointer;
	width: 35px;
	height: 35px;
}
.product-info div p:nth-child(1) {
	font-weight: bold;
	font-size: var(--md);
	margin-top: 0;
	margin-bottom: 4px;
}
.product-info div p:nth-child(2) {
	font-size: var(--sm);
	margin-top: 0;
	margin-bottom: 0;
	color: var(--very-light-pink);
}
@media (max-width: 640px) {
	.cards-container {
		grid-template-columns: repeat(auto-fill, 140px);
	}
	.ProductItem {
		width: 140px;
	}
	.ProductItem img {
		width: 140px;
		height: 140px;
		cursor: none;
	}
	.product-info figure img {
		cursor: none;
	}
}
