@import 'vars';
@import 'animations';

.ProductDetail {
    width: 360px;
    padding-bottom: 24px;
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-animation: fade-in-bck 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bck 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.ProductDetail-close {
    background: var(--white);
    width: 14px;
    height: 14px;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;
    padding: 12px;
    border-radius: 50%;
}
.ProductDetail-close:hover {
    cursor: pointer;
}
.ProductDetail > img:nth-child(2) {
    width: 100%;
    height: 360px;
    object-fit: cover;
    border-radius: 0 0 20px 20px;
}
@media (max-width: 640px) {
    .ProductDetail {
        width: 100%;
        overflow: scroll;
    }
}