@import 'vars';

.Orders {
	width: 100%;
	height: 100vh;
	display: grid;
	place-items: center;
}
.title {
	font-size: var(--lg);
	margin-bottom: 40px;
}
.Orders-container {
	display: grid;
	grid-template-rows: auto 1fr auto;
	width: 300px;
}
.Orders-content {
	display: flex;
	flex-direction: column;
}
