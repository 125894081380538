@import 'vars';
nav {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    border-bottom: 1px solid var(--very-light-pink);
    background-color: white;
  }
  .navbar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px 0;
  }
  .menu {
    display: none;
  }
  .nav-logo {
    width: 150px;
  }
  .navbar-left ul,
  .navbar-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    height: 60px;
  }
  .navbar-left {
    display: flex;
  }
  .navbar-left ul {
    margin-left: 50px;
    gap: 20px;
  }
  .navbar-left ul li a,
  .navbar-right ul li a {
    font-size: var(--lg);
    text-decoration: none;
    color: var(--very-light-pink);
    border: 1px solid var(--white);
    padding: 8px;
    border-radius: 8px;
  }
  .navbar-left ul li a:hover,
  .navbar-right ul li a:hover {
    border: 1px solid var(--hospital-green);
    color: var(--hospital-green);
  }
  .navbar-email {
    cursor: pointer;
    color: var(--very-light-pink);
    font-size: var(--md);
    margin-right: 12px;
    user-select: none;
  }
  .navbar-email:hover,
  .navbar-email-active {
    color: black;
  }
  .navbar-shopping-cart {
    position: relative;
    cursor: pointer;
    user-select: none;
  }
  .navbar-shopping-cart div {
    width: 16px;
    height: 16px;
    background-color: var(--hospital-green);
    border-radius: 50%;
    font-size: var(--sm);
    font-weight: bold;
    position: absolute;
    top: -6px;
    right: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 640px) {
    .menu {
      display: block;
    }
    .nav-logo {
      width: 100px;
    }
    .navbar-left ul {
      display: none;
    }
    .navbar-email {
      display: none;
      cursor: none;
    }
    .navbar-shopping-cart {
      cursor: none;
    }
  }