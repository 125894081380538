@import 'vars';
@import 'animations';

.MyOrder {
	width: 360px;
	padding: 24px;
	box-sizing: border-box;
	position: fixed;
	right: 0;
	background-color: white;
	top: 60px;
	bottom: 0;
	border-radius:  6px;
	border: 1px solid var(--very-light-pink);
	overflow: scroll;
	-webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

}
.MyOrder::-webkit-scrollbar {
    -webkit-appearance: none;
}

.MyOrder::-webkit-scrollbar:vertical {
    width:10px;
}

.MyOrder::-webkit-scrollbar-button:increment,.MyOrder::-webkit-scrollbar-button {
    display: none;
} 
.title-container {
	display: flex;
}
.title-container img {
	transform: rotate(180deg);
	margin-right: 14px;
	cursor: pointer;
}
.title {
	font-size: var(--lg);
	font-weight: bold;
}
.order {
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 16px;
	align-items: center;
	background-color: var(--text-input-field);
	margin-bottom: 24px;
	border-radius: 8px;
	padding: 0 24px;
}
.order p:nth-child(1) {
	display: flex;
	flex-direction: column;
}
.order p span:nth-child(1) {
	font-size: var(--md);
	font-weight: bold;
}
.order p:nth-child(2) {
	text-align: end;
	font-weight: bold;
}

.primary-button {
	background-color: var(--hospital-green);
	border-radius: 8px;
	border: none;
	color: var(--white);
	width: 100%;
	cursor: pointer;
	font-size: var(--md);
	font-weight: bold;
	height: 50px;
}
@media (max-width: 640px) {
	.MyOrder {
		width: 100%;
	}
	.title-container img {
		cursor: none;
	}
}
