@import 'vars';

.PasswordRecovery {
	width: 100%;
	height: 100vh;
	display: grid;
	place-items: center;
}
.PasswordRecovery-container {
	display: grid;
	grid-template-rows: auto 1fr auto;
	width: 300px;
}
.logo {
	width: 150px;
	margin-bottom: 48px;
	justify-self: center;
	display: none;
}
.form {
	display: flex;
	flex-direction: column;
}
.form a {
	color: var(--hospital-green);
	font-size: var(--sm);
	text-align: center;
	text-decoration: none;
	margin-bottom: 52px;
}
.label {
	font-size: var(--sm);
	font-weight: bold;
	margin-bottom: 4px;
}
.input {
	background-color: var(--text-input-field);
	border: none;
	border-radius: 8px;
	height: 30px;
	font-size: var(--md);
	padding: 6px;
	margin-bottom: 12px;
}
.input-email {
	margin-bottom: 22px;
}
.primary-button {
	background-color: var(--hospital-green);
	border-radius: 8px;
	border: none;
	color: var(--white);
	width: 100%;
	cursor: pointer;
	font-size: var(--md);
	font-weight: bold;
	height: 50px;
}
.secondary-button {
	background-color: var(--white);
	border-radius: 8px;
	border: 1px solid var(--hospital-green);
	color: var(--hospital-green);
	width: 100%;
	cursor: pointer;
	font-size: var(--md);
	font-weight: bold;
	height: 50px;
}
.login-button {
	margin-top: 14px;
	margin-bottom: 30px;
}
@media (max-width: 640px) {
	.logo {
		display: block;
	}
}
